// Story.js
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Story.css";
import { StoryContext } from "./StoryContext";
import { API_ENDPOINT } from "./var.js";

const Story = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData, settingsData } = useContext(StoryContext);
  const [storyData, setStoryData] = useState("");
  const [storyId, setStoryId] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [loading, setLoading] = useState(true);
  const { mode } = location.state || {}; // Mode for viewing experience
  const [summary, setSummary] = useState(""); // New state for story summary
  const [progress, setProgress] = useState(0); // State for loading bar
  const [isStoryGenerated, setIsStoryGenerated] = useState(false);

  // Simulate loading progress
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 10 : 100));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [loading]);

  const fetchStory = useCallback(
    async (formData, settingsData) => {
      if (isStoryGenerated) return; // Don't fetch if story is already generated

      setLoading(true);
      try {
        const response = await fetch(`${API_ENDPOINT}/generate-story`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData, settingsData }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);

          if (
            data.scenes &&
            data.scenes.length > 0 &&
            data.scenes[0].chunk_of_text
          ) {
            setStoryData(data.scenes[0].chunk_of_text);
            setStoryId(data.storyId);
            generateSummary(data.scenes);
            setIsStoryGenerated(true); // Mark story as generated
          } else {
            console.error("Invalid story data received:", data);
          }
        } else {
          console.error("Failed to fetch story data");
        }
      } catch (error) {
        console.error("Error fetching story data:", error);
      }

      setLoading(false);
    },
    [isStoryGenerated]
  );

  useEffect(() => {
    if (!isStoryGenerated) {
      fetchStory(formData, settingsData);
    }
  }, [formData, settingsData, fetchStory, isStoryGenerated]);

  // Generate the story title based on the child's name
  const generateTitle = () => {
    return `${formData["Name"]}'s Amazing Adventure`;
  };

  // Function to generate a summary of the story
  const generateSummary = (scenes) => {
    const summarySentences = scenes
      .slice(0, 3)
      .map(
        (scene) => scene.chunk_of_text.split(".").slice(0, 2).join(".") + "."
      );
    setSummary(summarySentences.join(" "));
  };

  const handleSave = () => {
    alert("Story saved!");
  };

  const handleGenerateAnother = () => {
    navigate("/form");
  };

  const handleTextToSpeech = () => {
    if ("speechSynthesis" in window) {
      if (isSpeaking) {
        window.speechSynthesis.cancel();
        setIsSpeaking(false);
      } else {
        const speech = new SpeechSynthesisUtterance(storyData);
        const voices = window.speechSynthesis.getVoices();
        const maleVoice =
          voices.find((voice) => voice.name.includes("Male")) || voices[0];
        speech.voice = maleVoice;

        speech.onend = () => {
          setIsSpeaking(false);
        };

        window.speechSynthesis.speak(speech);
        setIsSpeaking(true);
      }
    } else {
      alert("Sorry, your browser does not support Text-to-Speech.");
    }
  };

  const handleViewStoryboard = () => {
    navigate(`/storyboard/${storyId}`);
  };

  useEffect(() => {
    return () => {
      if ("speechSynthesis" in window) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  return (
    <div className="story-page">
      {/* Background image */}
      <div
        className="story-background"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/icons/Storybg.png"
          })`,
        }}
      ></div>
      {loading ? (
        <div className="loading-container">
          {/* Full-screen rocket loading image */}
          <img
            src={process.env.PUBLIC_URL + "/icons/loadingbg.png"}
            alt="Loading"
            className="loading-image"
          />
          {/* Vertical loading bar */}
          <div className="loading-bar-container">
            <div
              className="loading-bar"
              style={{ height: `${progress}%` }}
            ></div>
          </div>
          <div className="loading-text">Creating your magical adventure...</div>
        </div>
      ) : (
        <div
          className={`story-container ${
            mode === "narrator" ? "narrator-mode" : ""
          }`}
        >
          <h1 className="story-title">{generateTitle()}</h1>
          <div className="story-summary">
            <p className="summary-text">{summary || "No summary available."}</p>
          </div>
          {mode === "read" && (
            <p className="story-content">
              {storyData || "No story data available"}
            </p>
          )}
          <div className="controls">
            <button className="control-button" onClick={handleTextToSpeech}>
              <img
                src={
                  isSpeaking
                    ? process.env.PUBLIC_URL + "/icons/pause.png"
                    : process.env.PUBLIC_URL + "/icons/speaker.png"
                }
                alt={isSpeaking ? "Pause" : "Play"}
              />
            </button>
            <button className="control-button" onClick={handleViewStoryboard}>
              <img
                src={process.env.PUBLIC_URL + "/icons/reading-book.png"}
                alt="View Storyboard"
              />
            </button>
          </div>
          <div className="action-buttons">
            <button className="save-button" onClick={handleSave}>
              Save
            </button>
            <button
              className="generate-another-button"
              onClick={handleGenerateAnother}
            >
              Generate Another!
            </button>
          </div>
          <div className="story-id">
            <strong>Story ID:</strong> {storyId || "Not available"}
          </div>
        </div>
      )}
    </div>
  );
};

export default Story;
