import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";

const HomePage = () => {
  const navigate = useNavigate();
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <div className={`homepage-container ${fadeIn ? "fade-in" : ""}`}>
      {/* Home background image */}
      <img
        src={process.env.PUBLIC_URL + "/homescreen/homebackground.png"}
        alt="Home Background"
        className="home-background"
      />

      {/* Title */}
      <h1 className="homepage-title">Tales</h1>

      {/* Home Settings Button */}
      <button
        className="home-settings-button"
        onClick={() => navigate("/homesettings")}
      >
        <img
          src={process.env.PUBLIC_URL + "/homescreen/homesettings.png"}
          alt="Home Settings"
          className="home-settings"
        />
      </button>

      {/* Home History Button */}
      <button
        className="home-history-button"
        onClick={() => navigate("/dashboard")}
      >
        <img
          src={process.env.PUBLIC_URL + "/homescreen/homehistory.png"}
          alt="Home History"
          className="home-history"
        />
      </button>

      {/* Home Book Button */}
      <button className="home-book-button" onClick={() => navigate("/form")}>
        <img
          src={process.env.PUBLIC_URL + "/homescreen/homebook.png"}
          alt="Home Book"
          className="home-book"
        />
      </button>
    </div>
  );
};

export default HomePage;
