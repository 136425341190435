// Dashboard.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const [showAllStories, setShowAllStories] = useState(false);

  const stories = [
    { id: 1, title: "The Enchanted Forest" },
    { id: 2, title: "Pirate's Treasure" },
    { id: 3, title: "Journey to the Stars" },
  ];

  // Updated list of children's adventure-themed story titles
  const allStories = [
    { id: 4, title: "Magic Treehouse Adventure" },
    { id: 5, title: "The Lost Island of Dinosaurs" },
    { id: 6, title: "A Journey Through the Jungle" },
    { id: 8, title: "A Day in Space with Aliens" },
    { id: 9, title: "Underwater Kingdom of Mermaids" },
    { id: 11, title: "The Great Balloon Escape" },
    { id: 12, title: "Safari in the Enchanted Savannah" },
    { id: 14, title: "Adventures in the Snowy Mountains" },
    { id: 15, title: "The Magical Candy Land" },
    { id: 16, title: "Flying with Dragons" },
    { id: 18, title: "Robot Rescue Mission" },
    { id: 19, title: "Camping Under the Stars" },
    { id: 20, title: "The Rainbow Unicorn Chase" },
  ];

  return (
    <>
      {/* Background image */}
      <img
        src={process.env.PUBLIC_URL + "/homescreen/dashboardbackground.png"}
        alt="Dashboard Background"
        className="dashboard-background"
      />

      <div className="dashboard-container">
        {/* Back Button */}
        <button
          className="back-button"
          onClick={() => navigate("/home")}
          aria-label="Go Back"
        >
          &#8249;
        </button>

        {/* Story History Title */}
        <h1 className="story-history-header">Story History</h1>

        {/* Favorites Label */}
        <h2 className="favorites-title">Favorites</h2>

        <div className="dashboard-content">
          {/* Story List */}
          <div className="story-list">
            {stories.map((story) => (
              <div
                key={story.id}
                className="story-item"
                onClick={() => navigate(`/story/${story.id}`)}
              >
                <h3 className="story-title">{story.title}</h3>
              </div>
            ))}
          </div>

          {/* View All Stories Button */}
          <button
            className="view-all-button"
            onClick={() => setShowAllStories(true)}
          >
            View All Stories
          </button>

          {/* Create New Story Button */}
          <button
            className="create-story-button"
            onClick={() => navigate("/form")}
          >
            + Create New Adventure
          </button>
        </div>
      </div>

      {/* Overlay for All Stories */}
      {showAllStories && (
        <div className="story-overlay">
          <div className="overlay-content">
            <button
              className="overlay-back-button"
              onClick={() => setShowAllStories(false)}
            >
              &#8249;
            </button>
            {allStories.map((story) => (
              <div key={story.id} className="overlay-story-item">
                {story.title}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
