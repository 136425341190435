import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SettingsPage.css";
import { StoryContext } from "./StoryContext";

const SettingsPage = () => {
  const { settingsData, setSettingsData } = useContext(StoryContext);
  const navigate = useNavigate();
  const [brightness, setBrightness] = useState(100);
  const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("userSettings"));
    if (savedSettings && savedSettings.brightness) {
      setBrightness(savedSettings.brightness);
    }
  }, []);

  const handleSaveSettings = () => {
    localStorage.setItem(
      "userSettings",
      JSON.stringify({ ...settingsData, brightness })
    );
    alert("Settings Saved!");
    navigate("/form");
  };

  const handleSelection = (category, option) => {
    setSettingsData({ ...settingsData, [category]: option });
    setActiveDropdown(null); // Close the selection modal
  };

  return (
    <>
      {/* Overlay */}
      {activeDropdown && <div className="overlay"></div>}

      {/* Background image */}
      <img
        src={process.env.PUBLIC_URL + "/homescreen/advancedbg.png"}
        alt="Settings Background"
        className="settings-background"
        style={{ filter: `brightness(${brightness}%)` }}
      />

      {/* Back Arrow */}
      <button
        className={`back-button ${activeDropdown ? "hidden" : ""}`}
        onClick={() => navigate("/form")}
      >
        &#8249;
      </button>

      <div className={`settings-container ${activeDropdown ? "hidden" : ""}`}>
        <h2 className="settings-title">Story Customization</h2>

        {/* Content Sensitivity */}
        <div className="settings-section">
          <div className="selected-option">({settingsData.violenceLevel})</div>
          <h3 onClick={() => setActiveDropdown("violenceLevel")}>
            Content Sensitivity
          </h3>
        </div>

        {/* Language Level */}
        <div className="settings-section">
          <div className="selected-option">({settingsData.languageLevel})</div>
          <h3 onClick={() => setActiveDropdown("languageLevel")}>
            Language Level
          </h3>
        </div>

        {/* Moral Themes */}
        <div className="settings-section">
          <div className="selected-option">({settingsData.moralTheme})</div>
          <h3 onClick={() => setActiveDropdown("moralTheme")}>Moral Themes</h3>
        </div>

        {/* Story Tone */}
        <div className="settings-section">
          <div className="selected-option">({settingsData.storyTone})</div>
          <h3 onClick={() => setActiveDropdown("storyTone")}>Story Tone</h3>
        </div>

        <div className="save-settings">
          <button onClick={handleSaveSettings}>Save Settings</button>
        </div>
      </div>

      {/* Dropdown */}
      {activeDropdown && (
        <div className="dropdown">
          {activeDropdown === "violenceLevel" &&
            [
              "No Scary Elements",
              "Mild Scary Elements",
              "Educational Content Only",
            ].map((option) => (
              <p
                key={option}
                onClick={() => handleSelection("violenceLevel", option)}
              >
                {option}
              </p>
            ))}

          {activeDropdown === "languageLevel" &&
            ["Ages 2-3", "Ages 4-6", "Ages 7-9", "Ages 10-12", "Teens"].map(
              (option) => (
                <p
                  key={option}
                  onClick={() => handleSelection("languageLevel", option)}
                >
                  {option}
                </p>
              )
            )}

          {activeDropdown === "moralTheme" &&
            [
              "Friendship",
              "Courage",
              "Honesty",
              "Kindness",
              "Respect",
              "Teamwork",
              "Responsibility",
            ].map((option) => (
              <p
                key={option}
                onClick={() => handleSelection("moralTheme", option)}
              >
                {option}
              </p>
            ))}

          {activeDropdown === "storyTone" &&
            [
              "Light-hearted",
              "Adventurous",
              "Suspenseful",
              "Educational",
              "Inspirational",
              "Mystical",
            ].map((option) => (
              <p
                key={option}
                onClick={() => handleSelection("storyTone", option)}
              >
                {option}
              </p>
            ))}

          <button
            className="dropdown-save-button"
            onClick={() => setActiveDropdown(null)}
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default SettingsPage;
