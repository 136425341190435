import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Storyboard.css";
import { API_ENDPOINT } from "./var.js";

const Storyboard = () => {
  const { storyId } = useParams();
  const navigate = useNavigate();
  const [story, setStory] = useState(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [highlightedBlockIndex, setHighlightedBlockIndex] = useState(-1);
  // const [prefetchedImages, setPrefetchedImages] = useState({});
  const [generatedImages, setGeneratedImages] = useState({});
  const [blockIndex, setBlockIndex] = useState(0);
  const blockIndexRef = useRef(0);
  const [preloadedImages, setPreloadedImages] = useState({});
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);

  // Fetch story data
  console.log(blockIndex);
  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}/story/${storyId}`);
        if (response.ok) {
          const data = await response.json();
          setStory(data);
        } else {
          console.error("Failed to fetch story data");
        }
      } catch (error) {
        console.error("Error fetching story data:", error);
      }
    };
    fetchStory();
  }, [storyId]);

  const preloadImage = useCallback((imageUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setIsFirstImageLoaded(true);
        resolve(imageUrl);
      };
      img.onerror = () =>
        reject(new Error(`Failed to load image: ${imageUrl}`));
    });
  }, []);

  // Function to generate an image for a given scene index
  const generateImage = useCallback(
    async (sceneIndex) => {
      if (generatedImages[sceneIndex]) return; // Don't generate if image already exists

      const scene = story?.scenes?.[sceneIndex];
      if (!scene || !scene.imagePrompt) {
        console.error("Invalid scene data or missing image prompt");
        return null;
      }

      try {
        console.log(
          `Generating image for scene ${sceneIndex} with prompt:`,
          scene.imagePrompt + scene.keywords.join(", ")
        );
        const response = await fetch(`${API_ENDPOINT}/generate-image`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storyId,
            sceneIndex,
            imagePrompt: scene.imagePrompt + scene.keywords.join(", "),
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.imageUrl;

          // Preload the generated image
          await preloadImage(imageUrl);

          setStory((prevStory) => ({
            ...prevStory,
            scenes: prevStory.scenes.map((scene, index) =>
              index === sceneIndex ? { ...scene, imageUrl: imageUrl } : scene
            ),
          }));
          setGeneratedImages((prev) => ({ ...prev, [sceneIndex]: true }));
          setPreloadedImages((prev) => ({ ...prev, [sceneIndex]: imageUrl }));
          return imageUrl;
        } else {
          const errorData = await response.json();
          console.error("Failed to generate image:", errorData);
        }
      } catch (error) {
        console.error("Error generating image:", error);
      }

      return null;
    },
    [story, storyId, generatedImages, preloadImage]
  );

  // Handle image generation and preloading for the current scene
  useEffect(() => {
    if (story && story.scenes && !generatedImages[currentSceneIndex]) {
      generateImage(currentSceneIndex);
    }
  }, [story, currentSceneIndex, generateImage, generatedImages]);

  // Prefetch and preload the next image
  useEffect(() => {
    if (story && story.scenes && currentSceneIndex + 1 < story.scenes.length) {
      const nextIndex = currentSceneIndex + 1;
      if (!generatedImages[nextIndex]) {
        generateImage(nextIndex).then((imageUrl) => {
          if (imageUrl) {
            preloadImage(imageUrl)
              .then(() => {
                console.log(`Preloaded image for scene ${nextIndex}`);
              })
              .catch((error) => {
                console.error(
                  `Failed to preload image for scene ${nextIndex}:`,
                  error
                );
              });
          }
        });
      } else if (preloadedImages[nextIndex]) {
        preloadImage(preloadedImages[nextIndex])
          .then(() => {
            console.log(`Preloaded image for scene ${nextIndex}`);
          })
          .catch((error) => {
            console.error(
              `Failed to preload image for scene ${nextIndex}:`,
              error
            );
          });
      }
    }
  }, [
    story,
    currentSceneIndex,
    generateImage,
    generatedImages,
    preloadedImages,
    preloadImage,
  ]);

  // Handle text-to-speech and punctuation-based highlighting
  const handleTextToSpeech = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setHighlightedBlockIndex(-1);
    } else {
      const currentText =
        story.scenes[currentSceneIndex]?.text || "No scene text available";

      const punctuationBlocks = currentText
        .split(/([.,!?;])/g)
        .reduce((acc, curr, index, arr) => {
          if (index % 2 === 0) {
            acc.push(curr + (arr[index + 1] || ""));
          }
          return acc;
        }, []);

      const speech = new SpeechSynthesisUtterance(currentText);
      setBlockIndex(0);
      blockIndexRef.current = 0;

      speech.onboundary = (event) => {
        const charIndex = event.charIndex;
        let nextBlockStartIndex = 0;
        for (let i = 0; i < blockIndexRef.current; i++) {
          nextBlockStartIndex += punctuationBlocks[i].length;
        }

        if (
          charIndex >= nextBlockStartIndex &&
          blockIndexRef.current < punctuationBlocks.length
        ) {
          setHighlightedBlockIndex(blockIndexRef.current);
          blockIndexRef.current++;
          setBlockIndex(blockIndexRef.current);
        }
      };

      speech.onend = () => {
        setIsSpeaking(false);
        setHighlightedBlockIndex(-1);
        setBlockIndex(0);
        blockIndexRef.current = 0;
      };

      window.speechSynthesis.speak(speech);
      setIsSpeaking(true);
    }
  };

  // Handle scene navigation
  const handlePrevious = () => {
    setCurrentSceneIndex((prev) => Math.max(0, prev - 1));
    setHighlightedBlockIndex(-1);
  };

  const handleNext = () => {
    setCurrentSceneIndex((prev) => {
      const next = Math.min(story.scenes.length - 1, prev + 1);
      return next;
    });
    setHighlightedBlockIndex(-1);
  };

  // Navigate back to story list
  const handleClose = () => {
    navigate("/story");
  };

  if (!story || !isFirstImageLoaded) return <div>Loading...</div>;

  const currentScene = story.scenes[currentSceneIndex];

  const punctuationBlocks = currentScene.text
    .split(/([.,!?;])/g)
    .reduce((acc, curr, index, arr) => {
      if (index % 2 === 0) {
        acc.push(curr + (arr[index + 1] || ""));
      }
      return acc;
    }, []);

  return (
    <div className="storyboard-container">
      {/* Scene background image */}
      <div
        className="scene-image"
        style={{
          backgroundImage: `url(${
            currentScene.imageUrl || "https://example.com/test-image.jpg"
          })`,
        }}
      >
        {/* Header with back and bookmark buttons */}
        <div className="story-header">
          <button className="back-button" onClick={handleClose}>
            <img
              src={process.env.PUBLIC_URL + "/icons/back-arrow.png"}
              alt="Back"
            />
          </button>
          <h2 className="chapter-title">Chapter {currentSceneIndex + 1}</h2>
          <button className="bookmark-button">
            <img
              src={process.env.PUBLIC_URL + "/icons/bookmark.png"}
              alt="Bookmark"
            />
          </button>
        </div>

        {/* Story Text Box */}
        <div className="story-text-box">
          <h3>A Journey of Friendship</h3>
          <p className="story-text">
            {punctuationBlocks.map((block, index) => (
              <span
                key={index}
                className={index === highlightedBlockIndex ? "highlighted" : ""}
              >
                {block}{" "}
              </span>
            ))}
          </p>
        </div>
      </div>
      {/* Playback controls */}
      <div className="controls">
        <button
          className="previous-button"
          onClick={handlePrevious}
          disabled={currentSceneIndex === 0}
        >
          <img
            src={process.env.PUBLIC_URL + "/icons/previous.png"}
            alt="Previous"
          />
        </button>
        <button className="play-button" onClick={handleTextToSpeech}>
          <img
            src={
              isSpeaking
                ? process.env.PUBLIC_URL + "/icons/pause.png"
                : process.env.PUBLIC_URL + "/icons/play.png"
            }
            alt="Play/Pause"
          />
        </button>
        <button
          className="next-button"
          onClick={handleNext}
          disabled={currentSceneIndex === story.scenes.length - 1}
        >
          <img src={process.env.PUBLIC_URL + "/icons/next.png"} alt="Next" />
        </button>
        <button className="settings-button">
          <img
            src={process.env.PUBLIC_URL + "/icons/settings.png"}
            alt="Settings"
          />
        </button>
      </div>
    </div>
  );
};

export default Storyboard;
