import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./HomeSettings.css";

const HomeSettings = () => {
  const navigate = useNavigate();
  const [brightness, setBrightness] = useState(100);
  const [feedback, setFeedback] = useState("");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selections, setSelections] = useState({
    backgroundTheme: "Enchanted Castle",
    backgroundMusic: "Lullaby",
    language: "English",
  });

  // Load saved settings from localStorage when the component mounts
  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("userSettings"));
    if (savedSettings) {
      setSelections(
        savedSettings.selections || {
          backgroundTheme: "Enchanted Castle",
          backgroundMusic: "Lullaby",
          language: "English",
        }
      );
      setBrightness(savedSettings.brightness || 100);
    }
  }, []); // Empty dependency array to run only once

  const handleSaveSettings = () => {
    // Save settings to localStorage
    localStorage.setItem(
      "userSettings",
      JSON.stringify({ selections, brightness })
    );
    alert("Settings Saved!");
    navigate("/home");
  };

  const handleDropdownSave = () => {
    setActiveDropdown(null);
  };

  const handleSelection = (category, option) => {
    setSelections((prev) => ({ ...prev, [category]: option }));
  };

  return (
    <>
      {/* Overlay */}
      {activeDropdown && <div className="overlay"></div>}

      {/* Background image */}
      <img
        src={process.env.PUBLIC_URL + "/homescreen/homesettingsbackground.png"}
        alt="Home Settings Background"
        className="settings-background"
        style={{ filter: `brightness(${brightness}%)` }}
      />

      {/* Back Arrow */}
      <button className="back-button" onClick={() => navigate("/home")}>
        &#8249;
      </button>

      <div className="homesettings-container">
        <h2 className="settings-title">Home Page Settings</h2>

        {/* Background Theme */}
        <div className="settings-section">
          <div className="selected-option">({selections.backgroundTheme})</div>
          <h3
            onClick={() =>
              setActiveDropdown(
                activeDropdown === "backgroundTheme" ? null : "backgroundTheme"
              )
            }
          >
            Background Theme
          </h3>
        </div>

        {/* Background Music */}
        <div className="settings-section">
          <div className="selected-option">({selections.backgroundMusic})</div>
          <h3
            onClick={() =>
              setActiveDropdown(
                activeDropdown === "backgroundMusic" ? null : "backgroundMusic"
              )
            }
          >
            Background Music
          </h3>
        </div>

        {/* Language Preference */}
        <div className="settings-section">
          <div className="selected-option">({selections.language})</div>
          <h3
            onClick={() =>
              setActiveDropdown(
                activeDropdown === "language" ? null : "language"
              )
            }
          >
            Language Preference
          </h3>
        </div>

        {/* Brightness */}
        <div className="settings-section">
          <h3>Brightness</h3>
          <input
            className="settings-range"
            type="range"
            min="50"
            max="150"
            value={brightness}
            onChange={(e) => setBrightness(Number(e.target.value))}
          />
        </div>

        {/* Feedback */}
        <div className="settings-section">
          <h3>Feedback</h3>
          <textarea
            className="settings-textarea"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Provide feedback here..."
          ></textarea>
        </div>

        {/* Submit Feedback Button */}
        {feedback && (
          <div className="submit-feedback-button show">
            <button
              onClick={() => {
                alert("Feedback submitted!");
                setFeedback("");
              }}
            >
              Submit Feedback
            </button>
          </div>
        )}

        {/* Save Settings Button */}
        <div className="save-settings">
          <button onClick={handleSaveSettings}>Save Settings</button>
        </div>
      </div>

      {/* Dropdown */}
      {activeDropdown && (
        <div className="dropdown">
          {activeDropdown === "backgroundTheme" &&
            [
              "Enchanted Castle",
              "Knight vs Dragon",
              "Children under Oak",
              "Pirate Chest",
            ].map((option) => (
              <p
                key={option}
                onClick={() => handleSelection("backgroundTheme", option)}
              >
                {option}
              </p>
            ))}

          {activeDropdown === "backgroundMusic" &&
            [
              "Lullaby",
              "Calming",
              "Nature Sounds",
              "Soft Piano",
              "Gentle Waves",
            ].map((option) => (
              <p
                key={option}
                onClick={() => handleSelection("backgroundMusic", option)}
              >
                {option}
              </p>
            ))}

          {activeDropdown === "language" &&
            ["English", "Spanish", "French", "German"].map((option) => (
              <p
                key={option}
                onClick={() => handleSelection("language", option)}
              >
                {option}
              </p>
            ))}

          <button className="dropdown-save-button" onClick={handleDropdownSave}>
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default HomeSettings;
