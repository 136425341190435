import React, { createContext, useState } from "react";

export const StoryContext = createContext();

export const StoryProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    Name: "",
    Personality: "",
    Environment: "",
    Adventure: "",
    Companion: "",
    "Favorite Color": "",
    "Story Length": "",
  });
  const [settingsData, setSettingsData] = useState({
    violenceLevel: "No Violence",
    languageLevel: "Ages 2-3",
    moralTheme: "Friendship",
    storyTone: "Light-hearted",
    backgroundTheme: "Fantasy Forest",
    voiceType: "Friendly",
    backgroundMusic: "Soothing",
  });

  return (
    <StoryContext.Provider
      value={{ formData, setFormData, settingsData, setSettingsData }}
    >
      {children}
    </StoryContext.Provider>
  );
};



