import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ScreenOne.css";

const ScreenOne = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();

  const handleAnimationEnd = () => {
    setTimeout(() => {
      setIsAnimating(false);
      navigate("/home");
    }, 500); // Short delay to let the sparks show
  };

  const handleClick = () => {
    console.log("Wizard clicked!");
    setIsAnimating(true);
  };

  return (
    <div
      className="screenone-container"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/screenone/background.jpg"
        })`,
      }}
    >
      <img
        src={process.env.PUBLIC_URL + "/screenone/wizard.png"}
        alt="Wizard"
        className="wizard"
        onClick={handleClick} // Make sure this event is attached
      />
      <img
        src={process.env.PUBLIC_URL + "/screenone/staff.png"}
        alt="Staff"
        className={`staff ${isAnimating ? "animate-staff" : ""}`}
        onAnimationEnd={handleAnimationEnd} // Handle end of animation
        onClick={handleClick} // Trigger animation on click
      />
      <img
        src={process.env.PUBLIC_URL + "/screenone/screenonesparks.png"}
        alt="Sparks"
        className={`sparks ${isAnimating ? "show-sparks" : "hide-sparks"}`}
        onClick={handleClick} // Trigger animation on click
      />
      <div className="env-indicator">Env Mode: {process.env.NODE_ENV}</div>
    </div>
  );
};

export default ScreenOne;
